/* google font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

:root {
  /* font and typography */
  --body-font: "Poppins", sans-serif;
  /* font weight */
  --font-extra-light: 200;
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-semi-bolt: 600;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: rgb(180, 176, 176);
}
